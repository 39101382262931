<template>
   
      <div class="page-prox" id="Proximamente">
      
      </div>
  
</template>



<script>

export default {
  name: 'Proximamente',

    data () {
  return {
    
  }
}
}




</script>
<style scoped>

div#Proximamente {
    height: 100vh;
}



</style>
